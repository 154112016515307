import './App.css';
import Header from './Components/Header';
import Home from './Components/Home';
import Footer from './Components/Footer';
import { useRoutes } from 'raviger';
import Routes from './Routing/Routes';

function App() {
  const routeResult = useRoutes(Routes)
  return (
    <div className="App">
      <Header />
     {routeResult}
      <Footer />
    </div>
  );
}

export default App;