import React from 'react'
import Facebook from "../Shared/SocialMediaLogos/facebook.svg"
import Twitter from "../Shared/SocialMediaLogos/twitter.svg"
import Linkedin from "../Shared/SocialMediaLogos/linkedin.svg"
import Youtube from "../Shared/SocialMediaLogos/youtube.svg"
import Instagram from "../Shared/SocialMediaLogos/instagram.svg"
import LupovisLogo from "../Shared/LupovisLogos/logo4.svg"

export default function Footer() {
    return (
        <div className='footer'>
            <div className='footer-top'>
                <div className='container'>
                    <div className='row'>
                        <div className='footer-column-left'>
                            <a href='https://www.lupovis.io'>
                                <img className='footer-logo' src={LupovisLogo}></img>
                            </a>
                            <div className='footer-social-icons'>
                                <a className='footer-social-icon' href='https://www.facebook.com/lupovisdefence'>
                                    <img className='footer-icon' src={Facebook} alt="Lupovis Prowl Threat Reputation Facebook"></img>
                                </a>
                                <a className='footer-social-icon' href='https://twitter.com/LupovisDefence'>
                                    <img className='footer-icon' src={Twitter} alt="Lupovis Prowl Threat Reputation Twitter"></img>
                                </a>
                                <a className='footer-social-icon' href='https://www.linkedin.com/company/lupovis'>
                                    <img className='footer-icon' src={Linkedin} alt="Lupovis Prowl Threat Reputation Linkedin"></img>
                                </a>
                                <a className='footer-social-icon' href='https://www.youtube.com/channel/UCEY_MHmS4JD-g-ZYYjmsAGw'>
                                    <img className='footer-icon' src={Youtube} alt="Lupovis Prowl Threat Reputation Youtube"></img>
                                </a>
                                <a className='footer-social-icon' href='https://www.instagram.com/'>
                                    <img className='footer-icon' src={Instagram} alt="Lupovis Prowl Threat Reputation Instagram"></img>
                                </a>
                            </div>
                        </div>


                        <div className='footer-column-right'>
                            <div className='footer-menu'>
                                <div className='footer-menu-product footer-menu-block'>
                                    <span className='footer-menu-title body-btn body-main'>Product</span>
                                    <ul id='menu-product' className='footer-menu-list caption'>
                                        <li id='menu-product' className='footer-menu-item'>
                                            <a href='https://www.lupovis.io/deception-as-a-service/'>Deception as a Service</a>
                                        </li>
                                        <li id='menu-product' className='footer-menu-item'>
                                            <a href='https://www.lupovis.io/how-it-works/'>How It Works</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className='footer-menu-solutions footer-menu-block'>
                                    <span className='footer-menu-title body-btn body-main'>Solutions</span>
                                    <ul id='menu-product' className='footer-menu-list caption'>
                                        <li id='menu-product' className='footer-menu-item'>
                                            <a href='https://www.lupovis.io/higher-education/'>Higher Education</a>
                                        </li>
                                        <li id='menu-product' className='footer-menu-item'>
                                            <a href='https://www.lupovis.io/manufacturing/'>Manufacturing</a>
                                        </li>
                                        <li id='menu-product' className='footer-menu-item'>
                                            <a href='https://www.lupovis.io/finance/'>Finance</a>
                                        </li>
                                        <li id='menu-product' className='footer-menu-item'>
                                            <a href='https://www.lupovis.io/insider-threat/'>Insider Threat</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className='footer-menu-company footer-menu-block'>
                                    <span className='footer-menu-title body-btn body-main'>Company</span>
                                    <ul id='menu-company' className='footer-menu-list caption'>
                                        <li id='menu-company' className='footer-menu-item'>
                                            <a href='https://www.lupovis.io/about-us/'>About Us</a>
                                        </li>
                                        <li id='menu-company' className='footer-menu-item'>
                                            <a href='https://www.lupovis.io/careers/'>Careers</a>
                                        </li>
                                        <li id='menu-company' className='footer-menu-item'>
                                            <a href='https://www.lupovis.io/press/'>Press</a>
                                        </li>
                                        <li id='menu-company' className='footer-menu-item'>
                                            <a href='https://www.lupovis.io/branding/'>Branding</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>


                            <div className='footer-vertical-line'></div>
                            <hr className='footer-horizontal-line'/>

                            <div className='footer-menu-right'>
                                <ul id='menu-pricing' className='footer-menu-right-list body-btn body-main'>
                                    <li id='menu-pricing' className='footer-menu-right-item body-btn'>
                                        <a href='https://www.lupovis.io/pricing/'>Pricing</a>
                                    </li>
                                    <li id='menu-pricing' className='footer-menu-right-item body-btn body-main'>
                                        <a href='https://www.lupovis.io/blog/'>Blog</a>
                                    </li>
                                    <li id='menu-pricing' className='footer-menu-right-item body-btn body-main'>
                                        <a href='mailto:support@lupovis.io'>Support</a>
                                    </li>
                                    <li id='menu-pricing' className='footer-menu-right-item body-btn body-main'>
                                        <a href='mailto:partner@lupovis.io'>Partner Login</a>
                                    </li>
                                    {/* <button className='menu-item body-btn body-main' > Whitepaper</button> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='footer-bottom'>
                <div className='container'>
                    <div className='footer-bottom-wrapper'>
                        <div className='footer-bottom-lupovis'>
                            <p className='footer-bottom-text caption'>© Lupovis 2022. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
