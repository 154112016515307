import React, { useEffect, useState } from "react";
import { GetIndustryInsights } from "../../Requests/Requests";
import TagTemplate from "../templates/TagTemplate";
import "./Styles.css";

export default function Insights() {
  const [industryInsights, setIndustryInsights] = useState([]);
  const sector = "Industrials";

  useEffect(() => {
    GetIndustryInsights(sector)
      .then((response) => response.json())
      .then((data) => {
        setIndustryInsights(data);
      })
      .catch((error) => {
        console.error("Error fetching industry insights:", error);
      });
  }, []);

  return (
    <div className="insights">
      <div className="container">
        <div className="row">
          <div className="unlock-list-button-wrapper">
            <a
              className="insights-button"
              href="https://www.lupovis.io/pricing-prowl/"
            >
              <span>Unlock the full list</span>
            </a>
          </div>
        </div>
        <div className="row">
          <div className="report-table-wrapper">
            {industryInsights.length !== 0 ? (
              <table className="report-table">
                <thead>
                  <tr>
                    <th>Attacker IP</th>
                    <th>TTPs</th>
                    <th>CVEs</th>
                    <th>ASN</th>
                    <th>Country</th>
                  </tr>
                </thead>
                <tbody>
                  {industryInsights.map((item, index) => (
                    <tr key={index}>
                      <td>{item.sourceip}</td>
                      <td class="custom-background">
                        <TagTemplate tags={item.ttps} isInTable={true} />
                      </td>
                      <td class="custom-background">
                        <TagTemplate tags={item.cves} isInTable={true} />
                      </td>
                      <td>{item.asn_org ? item.asn_org : "No ASN Data"}</td>

                      <td>{item.country}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <h1>Loading...</h1>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
