import React from "react";
import Logo from "../../Shared/LupovisLogos/Logo.svg";

export default function IPDataTemplate(props) {
  return (
    <>
      {props.queryOutput["Error"] ? (
        <div className="data-row">
          <img
            className="mini-logo"
            src={Logo}
            alt="Lupovis IP reputation and security mini-logo"
          ></img>
          <div className="data-text"> Error: {props.queryOutput["Error"]}</div>
        </div>
      ) : (
        <>
          {props.queryOutput["ip_address"] ? (
            <div className="data-row">
              <img
                className="mini-logo"
                src={Logo}
                alt="Lupovis IP reputation and security mini-logo"
              ></img>
              <div className="data-text">
                IP Address: {props.queryOutput["ip_address"]}
              </div>
            </div>
          ) : null}

          {props.queryOutput["postal_code"] ? (
            <div className="data-row">
              <img
                className="mini-logo"
                src={Logo}
                alt="Lupovis IP reputation and security mini-logo"
              ></img>
              <div className="data-text">
                Postal Code: {props.queryOutput["postal_code"]}
              </div>
            </div>
          ) : null}

          {props.queryOutput["city_name"] ? (
            <div className="data-row">
              <img
                className="mini-logo"
                src={Logo}
                alt="Lupovis IP reputation and security mini-logo"
              ></img>
              <div className="data-text">
                City: {props.queryOutput["city_name"]}
              </div>
            </div>
          ) : null}

          {props.queryOutput["subdivision_2_name"] ? (
            <div className="data-row">
              <img
                className="mini-logo"
                src={Logo}
                alt="Lupovis IP reputation and security mini-logo"
              ></img>
              <div className="data-text">
                Subdivision 1: {props.queryOutput["subdivision_2_name"]}
              </div>
            </div>
          ) : null}

          {props.queryOutput["subdivision_1_name"] ? (
            <div className="data-row">
              <img
                className="mini-logo"
                src={Logo}
                alt="Lupovis IP reputation and security mini-logo"
              ></img>
              <div className="data-text">
                Subdivision 2: {props.queryOutput["subdivision_1_name"]}
              </div>
            </div>
          ) : null}

          {props.queryOutput["country_iso_code"] ? (
            <div className="data-row">
              <img
                className="mini-logo"
                src={Logo}
                alt="Lupovis IP reputation and security mini-logo"
              ></img>
              <div className="data-text">
                Country ISO Code: {props.queryOutput["country_iso_code"]}
              </div>
            </div>
          ) : null}

          {props.queryOutput["country_name"] ? (
            <div className="data-row">
              <img
                className="mini-logo"
                src={Logo}
                alt="Lupovis IP reputation and security mini-logo"
              ></img>
              <div className="data-text">
                Country: {props.queryOutput["country_name"]}
              </div>
            </div>
          ) : null}

          {props.queryOutput["continent_name"] ? (
            <div className="data-row">
              <img
                className="mini-logo"
                src={Logo}
                alt="Lupovis IP reputation and security mini-logo"
              ></img>
              <div className="data-text">
                Continent: {props.queryOutput["continent_name"]}
              </div>
            </div>
          ) : null}

          {props.queryOutput["accuracy_radius"] ? (
            <div className="data-row">
              <img
                className="mini-logo"
                src={Logo}
                alt="Lupovis IP reputation and security mini-logo"
              ></img>
              <div className="data-text">
                Accuracy Radius: {props.queryOutput["accuracy_radius"]} miles
              </div>
            </div>
          ) : null}

          {props.queryOutput["latitude"] ? (
            <div className="data-row">
              <img
                className="mini-logo"
                src={Logo}
                alt="Lupovis IP reputation and security mini-logo"
              ></img>
              <div className="data-text">
                Latitude: {props.queryOutput["latitude"]}
              </div>
            </div>
          ) : null}

          {props.queryOutput["longitude"] ? (
            <div className="data-row">
              <img
                className="mini-logo"
                src={Logo}
                alt="Lupovis IP reputation and security mini-logo"
              ></img>
              <div className="data-text">
                Longitude: {props.queryOutput["longitude"]}
              </div>
            </div>
          ) : null}

          {props.queryOutput["time_zone"] ? (
            <div className="data-row">
              <img
                className="mini-logo"
                src={Logo}
                alt="Lupovis IP reputation and security mini-logo"
              ></img>
              <div className="data-text">
                Time Zone: {props.queryOutput["time_zone"]}
              </div>
            </div>
          ) : null}

          {props.queryOutput["autonomous_number_system"] ? (
            <div className="data-row">
              <img
                className="mini-logo"
                src={Logo}
                alt="Lupovis IP reputation and security mini-logo"
              ></img>
              <div className="data-text">
                ASN: {props.queryOutput["autonomous_number_system"]}
              </div>
            </div>
          ) : null}

          {props.queryOutput["autonomous_system_organisation"] ? (
            <div className="data-row">
              <img
                className="mini-logo"
                src={Logo}
                alt="Lupovis IP reputation and security mini-logo"
              ></img>
              <div className="data-text">
                ASN Name: {props.queryOutput["autonomous_system_organisation"]}
              </div>
            </div>
          ) : null}

          {props.queryOutput["attack_destinations"]?.length > 0 ? (
            <div className="data-row att-dest-countries">
              <img
                className="mini-logo"
                src={Logo}
                alt="Lupovis IP reputation and security mini-logo"
              ></img>
              <div className="data-text">Key Attack Destinations: </div>
              {props.queryOutput["attack_destinations"].map((item, i) =>
                i + 1 === props.queryOutput["attack_destinations"].length ? (
                  <p key={item}>{item} </p>
                ) : (
                  <p key={item}>{item}, </p>
                )
              )}
            </div>
          ) : null}
        </>
      )}
    </>
  );
}
