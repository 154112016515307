import React, { useEffect, useState } from "react";
import { BiSearchAlt } from "react-icons/bi";
import { MdOutlineClear } from "react-icons/md";
import { AiOutlineInfo } from "react-icons/ai";
import { GetIP, GetIPData } from "../Requests/Requests";
import IPDataTemplate from "./templates/IPDataTemplate";
import TagTemplate from "./templates/TagTemplate";
import Logo from "../Shared/LupovisLogos/Logo.svg";
import GeoMap from "./Map/GeoMap";

export default function Home() {
  const exampleIps = [
    "85.209.11.254",
    "45.95.169.184",
    "83.97.73.245",
    "34.78.6.216",
    "85.90.246.159",
  ];
  const [ipAddress, setIpAddress] = useState("");
  const [placeHolderClientIp, setPlaceHolderClientIp] = useState("");
  const [queryOutput, setQueryOutput] = useState();
  const [fetchingData, setFetchingData] = useState(false);
  const [tooltipHovered, setTooltipHovered] = useState();
  const [randmIp, setRandmIp] = useState(
    exampleIps[Math.floor(Math.random() * exampleIps.length)]
  );

  useEffect(() => {
    const ip = getURLParams();
    if (ip) {
      setIpAddress(ip);
      getIPData(ip);
    }
    getIP();

    const seenTooltip = localStorage.getItem("seenTooltip");
    if (seenTooltip) {
      setTooltipHovered(false);
    } else {
      setTooltipHovered(true);
    }
    localStorage.setItem("seenTooltip", true);
  }, []);

  const getIP = () => {
    GetIP()
      .then((res) => {
        if (res.status !== 200) return;
        return res.text();
      })
      .then((res) => {
        setPlaceHolderClientIp(res);
      });
  };

  const getIPData = (ip) => {
    let ipaddress = ip.replace(/\s/g, "");
    setIpAddress(ipaddress);
    setURLParams(ipaddress);
    setFetchingData(true);
    GetIPData(ipaddress)
      .then((res) => {
        if (res.status === 204) return;
        return res.json();
      })
      .then((res) => {
        if (!res) {
          setQueryOutput({
            Error: "No results found for " + ipaddress,
            tags: [],
          });
          setFetchingData(false);
          return;
        }
        apply_probe_tag(res);
      });
  };

  const apply_probe_tag = (res) => {
    if (res["attack_destinations"] && res["attack_destinations"].length > 0) {
      if (res["tags"] && res["tags"].length === 0) {
        res["tags"].push("Probing");
      }
    }
    setQueryOutput(res);
    setFetchingData(false);
  };

  const getURLParams = () => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get("ip");
  };

  const setURLParams = (ip) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("ip", ip);
    window.history.pushState(
      { newURL: "newURL" },
      "newURL",
      window.location.pathname + "?" + queryParams.toString()
    );
  };

  const deleteURLParams = () => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete("ip");
    window.history.pushState(
      { newURL: "newURL" },
      "newURL",
      window.location.pathname
    );
  };

  const ipInputUpdate = (e) => {
    setIpAddress(e.target.value);
  };

  const buttonClick = () => {
    if (ipAddress) {
      getIPData(ipAddress);
    } else if (placeHolderClientIp) {
      getIPData(placeHolderClientIp);
    } else {
      return;
    }
  };

  const clearButtonClick = () => {
    setFetchingData(false);
    setQueryOutput(null);
    setIpAddress("");
    deleteURLParams();
  };

  const viewInsights = () => {
    window.location.href = "/insights";
  };

  return (
    <section className="ipinfo">
      <div className="container">
        <div className="row">
          <div className="column-content">
            {queryOutput ? (
              <GeoMap queryOutput={queryOutput} />
            ) : (
              <>
                <h1 className="headline">Eliminate what doesn't matter!</h1>
                <div className="banner-text">
                  <h2>
                    We identify, collect, analyze, and classify adversaries for
                    you! With Prowl, you spend less time on what doesn't matter
                    more time concentrating on what does.
                  </h2>
                </div>
              </>
            )}
            <div className="home-page-button-wrapper">
              <button className="insights-button" onClick={viewInsights}>
                View our top threats to industry 4.0
              </button>
              <a
                className="insights-button view-pricing-button"
                href="https://www.lupovis.io/pricing-prowl/"
              >
                <span>Unlock the full list</span>
              </a>
            </div>
          </div>

          <div className="ipinfo-container">
            <div className="search-wrapper">
              <div className="search-box-wrapper">
                <input
                  className="search-button"
                  onChange={ipInputUpdate}
                  placeholder={
                    placeHolderClientIp
                      ? placeHolderClientIp
                      : "Search an IP Address..."
                  }
                  value={ipAddress}
                ></input>
                {fetchingData ? <div className="loader" /> : null}
              </div>
              <button
                className="search-button-wrapper"
                onClick={() => buttonClick(ipAddress)}
              >
                <BiSearchAlt className="search-button-icon" />
              </button>
              {queryOutput ? (
                <button
                  className="search-button-wrapper"
                  onClick={clearButtonClick}
                >
                  <MdOutlineClear className="search-button-icon" />
                </button>
              ) : (
                <button
                  className="search-button-wrapper tooltip"
                  onClick={() => getIPData(randmIp)}
                  onMouseEnter={() => {
                    setTooltipHovered(true);
                  }}
                  onMouseLeave={() => {
                    setTooltipHovered(false);
                  }}
                >
                  <div
                    className={
                      tooltipHovered
                        ? "tooltiptext"
                        : "tooltiptext hide-tooltip"
                    }
                  >
                    Search an IP address that raised an alert in your logs.
                    <br />
                    <br />
                    Alternatively, click below to gain insights on{" "}
                    <span className="link">{randmIp}</span>.
                  </div>
                  <AiOutlineInfo className="search-button-icon" />
                </button>
              )}
            </div>
            <div
              className="query-output-wrapper"
              style={{
                backgroundImage: queryOutput ? "none" : `url(${Logo})`,
                scale: queryOutput ? "100%" : "50%",
              }}
            >
              {queryOutput ? (
                <>
                  <TagTemplate tags={queryOutput.tags} isInTable={false} />
                  <IPDataTemplate queryOutput={queryOutput} />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
