export async function GetIP() {
  return fetch(`/api/GetIP`, {
    method: "GET",
    credentials: "include",
    header: {
      "Content-Type": "application/json",
    },
  });
}

export async function GetIPData(ipaddress) {
  const data = { ipaddress: ipaddress };
  return fetch(`/api/GetIPData`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}

export async function GetIndustryInsights(sector) {
  const data = { sector: sector };
  return fetch(`/api/GetIndustryInsights`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
}
