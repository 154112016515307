import React from 'react';

export default function TagTemplate(props) {
  const { tags, isInTable } = props;

  return (
    tags?.length > 0 ? tags.map((tag) => (
      tag ? <div className={`tag ${isInTable ? 'table-tag' : ''}`} key={tag}>{tag}</div> : null
    )) : null 
  );
}
