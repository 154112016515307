import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import "./Styles.css";

export default function GeoMap(props) {
  const geoUrl = "/world-countries.json";
  const { queryOutput } = props;

  return (
    <ComposableMap alt="Threat intelligence IP location map">
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography key={geo.rsmKey} geography={geo} fill="#fefefe" />
          ))
        }
      </Geographies>

      <Marker coordinates={[queryOutput["longitude"], queryOutput["latitude"]]}>
        <g
          fill="none"
          stroke="#ff754a"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(-12, -24)"
        >
          <circle cx="12" cy="10" r="3" />
          <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
        </g>
      </Marker>
    </ComposableMap>
  );
}
