import React, { useState } from "react";
import LupovisLogo from "../Shared/LupovisLogos/logo4.svg"

export default function Header() {
  const [menuState, setMenuState] = useState(true);

  return (
    <div className="header">
      <div className="container">
        <div className="row">
          <div className="header-spacing-container">
            <div className="header-logo-container">
              <a className="header-logo-wrapper" href="/">
                <img className="header-logo" src={LupovisLogo} alt="Lupovis Prowl threat intelligence logo"></img>
              </a>
            </div>

            <div className="header-navigation-wrapper">
              <div
                className={
                  menuState ? "navigation" : "navigation navigation-open"
                }
              >
                <div className="navigation-wrapper">
                  <ul className="navigation-list">
                    <li className="menu-item">
                      <a href="https://www.lupovis.io/lupovis-prowl/">
                        Product
                      </a>
                    </li>
                    <li className="menu-item">
                      <a href="https://www.lupovis.io/blog/">Blog</a>
                    </li>
                    <li className="menu-item">
                      <a href="https://www.lupovis.io/about-us/">About us</a>
                    </li>
                    <li className="menu-item">
                      <a href="https://www.lupovis.io/contact-us/">
                        Contact us
                      </a>
                    </li>
                  </ul>
                  <ul className="btn-list">
                    <li>
                      <a
                        className="navigation-button btn-secondary"
                        href="https://www.lupovis.io/contact-us/"
                      >
                        <span>Request a Demo</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className="navigation-button btn-secondary buy-now-btn"
                        href="http://aws.amazon.com/marketplace/pp/prodview-cr64x4lse5uui"
                      >
                        <span className="buy-now-span">Get API Now</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className={
                menuState
                  ? "header-burger-button burger-button"
                  : "header-burger-button burger-button burger-button-active"
              }
              onClick={() => setMenuState((prevState) => !prevState)}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
